.sidebar {
    width: 880px;

    .offcanvas-header {
        padding: 0;

        .btn-close {
            position: absolute;
            right: 32px;
            top: 15px;
            z-index: 10;
        }
    }

    .offcanvas-body {
        padding: 0;

        .nav {
            position: fixed;
            width: 100%;
            background: #fff;
            z-index: 9;
        }

        .tab-content {
            padding-top: 42px;
        }
    }

    .widget_wrapper {
        margin: .5em .5em 0 .5em;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: .25em;
        grid-template-areas:
            "about about about status connection purchase"
            "commands commands commands stock stock stock"
            "products products products products products products"
            "fin fin fin fin fin fin";
    }
    .widget {
        display: grid;
        grid-template-rows: 1.5em auto 2em;
        background-color: #2c3e4f;
        color: white;
        border-radius: 1em;
        padding: .75em;
        height: 120px;

        &__icon {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &__notes {
            font-size: .75em;
        }
        div {
            text-align: center;
        }

        &__about {
            grid-area: about;
            &__header {
                display: grid;
                grid-template-columns: 30px auto 30px;
            }
        }
        &__commands {
            grid-area: commands;
            &__wrapper {
                margin-top: 5px;
                height: 60px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1em;
            }
            &__item {
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                &__screenshot {
                    background-image: url("../../components/market/icons/commands/screen.png");
                }
                &__lock {
                    background-image: url("../../components/market/icons/commands/lock.png");
                }
                &__unlock {
                    background-image: url("../../components/market/icons/commands/unlock.png");
                }
                &__reboot {
                    background-image: url("../../components/market/icons/commands/reboot.png");
                }
            }
        }
        &__stock {
            grid-area: stock;
        }

        &__products {
            height: 300px;
            grid-area: products;
            grid-template-rows: 1.5em auto;
            &__wrapper {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &__fin {
            height: 300px;
            grid-area: fin;
            &__wrapper {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &__header {
            display: block;
        }
    }
}

@include media-breakpoint-down(sm) {
    .sidebar {
        .offcanvas-header {    
            .btn-close {
                right: 10px;
                top: 10px;
            }
            
        }

        .offcanvas-body {    
            .tab-content {
                padding-top: 40px;
            }
        }
    
        .offcanvas-body {
            .nav {
                font-size: 11px;
                flex-wrap: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;
                width: 92%;
            }
        }
    }
}