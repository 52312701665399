.w_row {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--no-mb {
    margin-bottom: 0;
  }
}

.mb {
  margin-bottom: 15px;

  &-50 {
    margin-bottom: 50px;
  }
}

.w_container {
  width: 100%;
  padding: 0 15px;
  max-width: 400px;
  margin: 0 auto;
}

.current_purchase_grid {
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 100px 1fr;

  border: 1px solid black;
  grid-gap: 1px;
  background-color: black;

  > div {
    background-color: white;
    padding: .3em;
  }

}

.current_purchase_lines, .current_purchase_pays {
  display: grid;
  grid-template-columns: 1fr repeat(2, 110px);


  grid-gap: 1px;
  padding: 0 !important;
  background-color: black !important;

  > div {
    background-color: white;
    padding: .3em;
  }
}
.current_purchase_pays {
  grid-template-columns: repeat(6, 1fr);
}
.current_purchase_money {
  text-align: right;
}